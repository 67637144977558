import React from 'react'
import { graphql } from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import MicrositePreviewGrid from '../components/microsite-preview-grid'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers'
// import MicrositeLayout from '../containers/micrositeLayout'

export const query = graphql`
  query HomePageQuery {
    productionOldStyle: allSanityMicrosite(
      filter: {
        slug: { current: { ne: null } }
        _id: { regex: "/^(?!(drafts)).+/" }
        workflowStatus: { in: ["Production"] }
        siteType: { eq: "Old Style" }
      }
      sort: { fields: [slug___current], order: ASC }
    ) {
      edges {
        node {
          id
          mainBannerImage {
            asset {
              _id
              gatsbyImageData(layout: FIXED, width: 100)
            }
            alt
          }
          title
          slug {
            current
          }
        }
      }
    }
    productionNewStyle: allSanityMicrosite(
      filter: {
        slug: { current: { ne: null } }
        _id: { regex: "/^(?!(drafts)).+/" }
        workflowStatus: { in: ["Production"] }
        siteType: { eq: "New Style" }
      }
      sort: { fields: [slug___current], order: ASC }
    ) {
      edges {
        node {
          id
          mainBannerImage {
            asset {
              _id
              gatsbyImageData(layout: FIXED, width: 100)
            }
            alt
          }
          title
          slug {
            current
          }
        }
      }
    }
    developmentOldStyle: allSanityMicrosite(
      filter: { slug: { current: { ne: null } }, siteType: { eq: "Old Style" } }
      sort: { fields: [slug___current], order: ASC }
    ) {
      edges {
        node {
          id
          mainBannerImage {
            asset {
              _id
              gatsbyImageData(layout: FIXED, width: 100)
            }
            alt
          }
          title
          slug {
            current
          }
        }
      }
    }
    developmentNewStyle: allSanityMicrosite(
      filter: { slug: { current: { ne: null } }, siteType: { eq: "New Style" } }
      sort: { fields: [slug___current], order: ASC }
    ) {
      edges {
        node {
          id
          mainBannerImage {
            asset {
              _id
              gatsbyImageData(layout: FIXED, width: 100)
            }
            alt
          }
          title
          slug {
            current
          }
        }
      }
    }
  }
`

const HomePage = (props) => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  // const environment = process.env.NODE_ENV
  const environment = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development'
  console.log(`Using environment: '${environment}'`)
  var micrositeNodesOldStyle = ''
  var micrositeNodesNewStyle = ''
  if (environment === 'production') {
    micrositeNodesOldStyle =
      data &&
      data.productionOldStyle &&
      mapEdgesToNodes(data.productionOldStyle).filter(filterOutDocsWithoutSlugs)
    micrositeNodesNewStyle =
      data &&
      data.productionNewStyle &&
      mapEdgesToNodes(data.productionNewStyle).filter(filterOutDocsWithoutSlugs)
  } else {
    micrositeNodesOldStyle =
      data &&
      data.developmentOldStyle &&
      mapEdgesToNodes(data.developmentOldStyle).filter(filterOutDocsWithoutSlugs)
    micrositeNodesNewStyle =
      data &&
      data.developmentNewStyle &&
      mapEdgesToNodes(data.developmentNewStyle).filter(filterOutDocsWithoutSlugs)
  }
  return (
    <Layout>
      <SEO title="Microsites" />
      <Container>
        <h1 className="mt-5 ml-10">Microsites</h1>
        <h2 className="mt-5 ml-12">Old Style</h2>
        {micrositeNodesOldStyle && micrositeNodesOldStyle.length > 0 && (
          <MicrositePreviewGrid nodes={micrositeNodesOldStyle} />
        )}

        <h2 className="mt-5 ml-12">New Style</h2>
        {micrositeNodesNewStyle && micrositeNodesNewStyle.length > 0 && (
          <MicrositePreviewGrid nodes={micrositeNodesNewStyle} />
        )}
      </Container>
    </Layout>
  )
}

export default HomePage
